import { useTranslate } from '../../hooks';
import { IClientOrderTracking } from '../../interfaces/ITracking';

interface Props {
  clientOrder: IClientOrderTracking;
}

function TrackingDeliveryInfoData(props: Props) {
  const { clientOrder } = props;
  const translate = useTranslate('Tracking.deliveryInfo');

  return (
    <div className="TrackingBodyData">
      <span className="TrackingBodyDataTitle">{translate('title')}</span>
      <br />
      {clientOrder.customerName && (
        <>
          {translate('name')}
          :&nbsp;
          {clientOrder.customerName}
          <br />
        </>
      )}
      {clientOrder.customerPhone && (
        <>
          {translate('contact')}
          :&nbsp;
          {clientOrder.customerPhone}
          <br />
        </>
      )}
      {clientOrder.shippingAddress.address2 && (
        <>
          {translate('additionalAddress')}
          :&nbsp;
          {clientOrder.shippingAddress.address2}
          <br />
        </>
      )}
      {clientOrder.note && (
        <>
          {translate('note')}
          :&nbsp;
          {clientOrder.note}
          <br />
        </>
      )}
    </div>
  );
}

export default TrackingDeliveryInfoData;
