import {
  BrowserRouter, Routes as Router, Route,
} from 'react-router-dom';
import ProtectedLayout from '../components/ProtectedLayout';
import Home from '../pages/Home';
import MerchandiseRepositions from '../pages/MerchandiseReposition';
import Premises from '../pages/Premises';
import Changes from '../pages/Changes';
import Information from '../pages/Information';
import BillingAndPayments from '../pages/BillingAndPayments';
import FulfillmentSales from '../pages/Reports/Sales';
import FulfilmentOrderDetail from '../pages/Reports/OrderDetail';
import FulfillmentStock from '../pages/Reports/Stock';
import ReceptionForm from '../pages/Receptions/ReceptionForm';
import ReceptionFormDetail from '../pages/Receptions/ReceptionFormDetail';
import ReceptionFormHistory from '../pages/Receptions/ReceptionFormHistory';
import BillingManual from '../pages/BillingManual';
import Products from '../pages/Reports/Products';
import ProductsCreate from '../pages/Products/ProductsCreate';
import AllProducts from '../pages/Products/AllProducts';
import DeliveryRatesCreate from '../pages/DeliveryRates/CreateDeliveryRates';
import AllDeliveryRates from '../pages/DeliveryRates/AllDeliveryRates';
import EditDeliveryRates from '../pages/DeliveryRates/EditDeliveryRates';
import EditProducts from '../pages/Products/EditProducts';
import UnitaryCreateDeliveryRates from '../pages/DeliveryRates/UnitaryCreateDeliveryRates';
import Orders from '../pages/Orders';
import WithdrawalAll from '../pages/Withdrawals/WithdrawalAll';
import WithdrawalCreate from '../pages/Withdrawals/WithdrawalCreate';
import WithdrawalDetail from '../pages/Withdrawals/WithdrawalDetail';
import UnitaryCreateProducts from '../pages/Products/UnitaryCreateProducts';
import Lots from '../pages/Reports/Lots';
import ProductCreatePack from '../pages/Products/ProductsCreatePack';
import ProductsPack from '../pages/Products/ProductsPack';
import Payments from '../pages/Payments';
import AllPaymentOrders from '../pages/PaymentOrders/AllPaymentOrders';
import { TrackingOrder } from '../pages/Tracking/TrackingOrder';

function Redirect({ url }: { url: string }) {
  window.location.href = url;
  return null;
}

function AuthenticatedRoutes() {
  return (
    <BrowserRouter>
      <Router>
        <Route
          path="/home"
          element={<ProtectedLayout />}
        >
          <Route
            path="news"
            element={<Home />}
          />
          <Route
            path="repositions"
            element={<MerchandiseRepositions />}
          />
          <Route
            path="premises"
            element={<Premises />}
          />
          <Route
            path="changes"
            element={<Changes />}
          />
          <Route
            path="information"
            element={<Information />}
          />
          <Route
            path="billingandpayments"
            element={<BillingAndPayments />}
          />
          <Route
            path="sales"
            element={<FulfillmentSales />}
          />
          <Route
            path="orderdetails"
            element={<FulfilmentOrderDetail />}
          />
          <Route
            path="stock"
            element={<FulfillmentStock />}
          />
          <Route
            path="products"
            element={<Products />}
          />
          <Route
            path="lots"
            element={<Lots />}
          />
          <Route
            path="reception"
            element={<ReceptionForm />}
          />
          <Route
            path="formshistory"
            element={<ReceptionFormHistory />}
          />
          <Route
            path="receptiondetail"
            element={<ReceptionFormDetail />}
          />
          <Route
            path="billingmanual"
            element={<BillingManual />}
          />
          <Route
            path="allproducts"
            element={<AllProducts />}
          />
          <Route
            path="allproducts/create"
            element={<ProductsCreate />}
          />
          <Route
            path="allproducts/update"
            element={<EditProducts />}
          />
          <Route
            path="allproducts/unitarycreate"
            element={<UnitaryCreateProducts />}
          />
          <Route
            path="alldeliveryrates"
            element={<AllDeliveryRates />}
          />
          <Route
            path="alldeliveryrates/create"
            element={<DeliveryRatesCreate />}
          />
          <Route
            path="alldeliveryrates/update"
            element={<EditDeliveryRates />}
          />
          <Route
            path="alldeliveryrates/unitarycreate"
            element={<UnitaryCreateDeliveryRates />}
          />
          <Route
            path="orders/create"
            element={<Orders />}
          />
          <Route
            path="withdrawals"
            element={<WithdrawalAll />}
          />
          <Route
            path="withdrawals/create"
            element={<WithdrawalCreate />}
          />
          <Route
            path="withdrawals/:clientOrderId"
            element={<WithdrawalDetail />}
          />
          <Route
            path="productspack"
            element={<ProductsPack />}
          />
          <Route
            path="packcreate"
            element={<ProductCreatePack />}
          />
          <Route
            path="payments"
            element={<Payments />}
          />
          <Route
            path="paymentorders"
            element={<AllPaymentOrders />}
          />
        </Route>
        <Route
          path="/tracking/:internalCode"
          element={<TrackingOrder />}
        />
        <Route
          path="whatsapp"
          element={<Redirect url="https://wa.me/56962073219" />}
        />
        <Route
          path="*"
          element={<Redirect url="/home/news" />}
        />
      </Router>
    </BrowserRouter>
  );
}

export default AuthenticatedRoutes;
