import { useContext, useEffect, useState } from 'react';
import { IClientOrderData } from '../../../interfaces/IOrder';
import { useTranslate } from '../../../hooks';
import SelectInput from '../../../components/HomeComponents/SelectInputName';
import { GlobalContext } from '../../../store';
import ILocal from '../../../interfaces/ILocal';

interface Props {
  goNextPage: () => void;
  clientOrderData: Partial<IClientOrderData>;
  setClientOrderData: React.Dispatch<React.SetStateAction<Partial<IClientOrderData>>>;
}

export default function Data(props: Props) {
  const {
    clientOrderData, setClientOrderData, goNextPage,
  } = props;
  const translate = useTranslate('Page.Withdrawals');
  const { api } = useContext(GlobalContext);
  const [name, setName] = useState<string>(clientOrderData.customerName || '');
  const [phone, setPhone] = useState<string>(clientOrderData.customerPhone || '');
  const [notes, setNotes] = useState<string>(clientOrderData.note || '');
  const [selectedMfc, setSelectedMfc] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(true);
  const [locals, setLocals] = useState<ILocal[]>([]);

  function saveData() {
    if (disable) return;
    const local = locals.find((loc) => (loc.name === selectedMfc));
    if (local) {
      setClientOrderData({
        ...clientOrderData, customerName: name, customerPhone: phone, note: notes, LocalId: local?.id,
      });
      goNextPage();
    }
  }

  // get fullfilment locals
  useEffect(() => {
    (async () => {
      const allLocalsData = await api.getLocals({ });
      setLocals(allLocalsData.locals);
      const newSelectedLocal = allLocalsData.locals.find((local) => local.id === clientOrderData.LocalId);
      if (newSelectedLocal) setSelectedMfc(newSelectedLocal.name);
    })();
  }, []);

  // Verification required inputs
  useEffect(() => {
    if (name.length > 0 && selectedMfc) {
      setDisable(false);
    }
  }, [name, selectedMfc]);

  return (
    <>
      <div className="PageSubtitleContainer">
        {translate('DataStep.subtitle')}
      </div>
      <div className="DirectionStepContainer">
        <div className="DirectionLabel">{translate('DataStep.mfc')}</div>
        <SelectInput
          styleWidth="26vw"
          title={translate('DataStep.selectTitle')}
          array={locals}
          selectedOption={selectedMfc}
          SetSelectedOption={setSelectedMfc}
          lightStyle={false}
        />
        <div className="DirectionLabel">{translate('DataStep.name')}</div>
        <input
          className="TextInput InputStyle"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="DirectionLabel">{translate('DataStep.phone')}</div>
        <input
          className="TextInput InputStyle"
          type="tel"
          placeholder="+56988804332"
          maxLength={12}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <div className="DirectionLabel">{translate('DataStep.note')}</div>
        <textarea
          className="TextInput InputStyle InputHeight"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <div className="BottomRow">
        <div
          className={`Button Primary ${disable ? 'Disable' : ''}`}
          onClick={saveData}
        >
          {translate('DataStep.goNextButton')}
        </div>
      </div>
    </>
  );
}
