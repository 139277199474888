import {
  useEffect, useState, useContext, useMemo,
} from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { GlobalContext } from '../../store';
import ProductSearch from '../../components/HomeComponents/ProductSearch';
import IProduct from '../../interfaces/IProduct';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import { IRowData, IColumnData } from '../../interfaces/INavigableTableObjects';
import NavigableTable from '../../NavigableTable/NavigableTable';
import { useTranslate } from '../../hooks';
import IProductStock from '../../interfaces/IProductStock';
import textInputToNumber from '../../helpers/textInputToNumber';
import ConfirmDialog from '../../components/HomeComponents/ConfirmDialog';
import { IClientOrderData } from '../../interfaces/IOrder';

interface Props {
  goNextPage: () => void;
  goPreviousPage: () => void;
  clientOrderData: IClientOrderData;
  orderDetails: IRowData[];
  setOrderDetails: React.Dispatch<React.SetStateAction<IRowData[]>>;
  setOpenAlertDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAlertTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalAlertText: React.Dispatch<React.SetStateAction<string>>;
}

export default function Products(props: Props) {
  const {
    clientOrderData, orderDetails, setOrderDetails, goNextPage, goPreviousPage, setOpenAlertDialog,
    setModalAlertTitle, setModalAlertText,
  } = props;
  const translate = useTranslate('Page.CreateOrder');
  const { api, client } = useContext(GlobalContext);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<IRowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [productsAdded, setProductsAdded] = useState<IRowData[]>([]);
  const [confirmProductAlert, setConfirmProductAlert] = useState<boolean>(false);
  const [confirmProducts, setConfirmProducts] = useState<string>('');
  const disableNextStep = useMemo<boolean>(() => (
    filteredProducts?.filter((product) => (product.row[1].value as number) > 0).length === 0
  ), [filteredProducts]);

  // define data of columns
  const columns: IColumnData[] = [
    { title: translate('Columns.products'), type: 'text', editable: false },
    { title: translate('Columns.quantity'), type: 'number', editable: true },
    { title: translate('Columns.price'), type: 'number', editable: true },
    { title: translate('Columns.inventory'), type: 'inventory', editable: false },
  ];

  async function handleGoNextPage() {
    const data = orderDetails
      ?.filter((product) => (product.row[1].value as number) > 0);
    if (data.length === 0) {
      setModalAlertTitle(translate('errorTitle'));
      setModalAlertText(translate('errorText'));
      setOpenAlertDialog(true);
    } else {
      // Process price - pvp requirements
      const productIds = data.map((dataRow: IRowData) => dataRow.index);
      const response = await api.getClientProducts({ filter: { id: productIds } });
      data.forEach((dataRow: IRowData) => {
        const product = response.products.find((p) => p.id === dataRow.index);
        const price = dataRow.row[2].value as number;
        dataRow.row[2].value = (
          product && product.pvp && price < product.pvp * 0.7 && clientOrderData.OrderType?.name !== 'B2B'
        ) ? product.pvp : dataRow.row[2].value;
      });
      setProductsAdded(data);
      setConfirmProductAlert(true);
    }
  }

  function handleUpdateSearch(newValue: string) {
    if (orderDetails.length === 0) return;
    const list = (orderDetails)
      .filter((element) => (element.row[0].value as string)
        .toLowerCase().includes(newValue.toLowerCase()));
    setFilteredProducts(list);
  }

  async function showInventory(productId: number) {
    setLoading(true);
    const productStock = await api.getProductStock(productId);
    let message = '';
    productStock.forEach((stock: IProductStock) => {
      const availableStock = Math.max(Number(stock.available), 0);
      message += `- ${
        textInputToNumber(availableStock.toString(), false)
      } ${translate('ProductStep.modalDescription')} `
      + `${capitalizeFirstLetter(stock.Local.name)}`;
      message += '\n';
    });
    setModalAlertTitle(translate('ProductStep.modalTitle'));
    setModalAlertText(message);
    setLoading(false);
    setOpenAlertDialog(true);
  }

  // If user confirms products added, go to next page
  useEffect(() => {
    if (confirmProducts === 'true') {
      goNextPage();
    }
  }, [confirmProducts]);

  // Se initial data
  useEffect(() => {
    (async () => {
      const response = await api.getClientProducts({});
      setProducts(response.products);
      if (orderDetails.length === 0) {
        const productsList = response.products.map((product: IProduct) => {
          const cellData = [
            { index: 0, value: `${product?.rawSku}||${product?.name}`, isDisable: false },
            { index: 1, value: 0, isDisable: false },
            { index: 2, value: product?.pvp || 0, isDisable: false },
            { index: 3, value: translate('ProductStep.inventory'), isDisable: false },
          ];
          return { index: product.id, row: cellData };
        });
        setFilteredProducts(productsList);
        setOrderDetails(productsList);
      } else {
        setFilteredProducts([...orderDetails]);
      }
    })();
  }, []);

  return (
    <>
      <ConfirmDialog
        data={productsAdded}
        type="rowdata"
        description={
          translate(
            'ProductStep.confirmDialog',
            { productsLength: productsAdded.length },
          )
        }
        open={confirmProductAlert}
        setOpen={setConfirmProductAlert}
        setConfirmData={setConfirmProducts}
      />
      <div className="PageSubtitleContainer">
        {translate('ProductStep.subtitle')}
        <div
          className="UploadTutorialText"
          onClick={
            () => window.open(
              'https://get-nomad.notion.site/Creaci-n-pedidos-Manuales-para-Clientes-'
              + 'fe34bcf16269443a903a44b94e237148?pvs=4',
              '_blank',
            )
          }
        >
          {translate('ProductStep.orderCreationTutorial')}
        </div>
        <p className="Placeholder">
          {translate('ProductStep.placeholder')}
        </p>
      </div>
      <div className="SearchProductContainer">
        <p>{translate('product')}</p>
        <ProductSearch
          onUpdateSearch={handleUpdateSearch}
          allProducts={products}
          type="product"
        />
      </div>
      {filteredProducts.length > 0 && (
        <>
          <div className="AddedProductsTable">
            <NavigableTable
              dataToShow={filteredProducts}
              setDataToShow={setFilteredProducts}
              columns={columns}
              loading={loading}
              inventory={showInventory}
            />
          </div>
          <div className="BottomRow">
            { client?.hasB2B && (
              <div
                className="GoBackOrderPage"
                onClick={goPreviousPage}
              >
                <LeftOutlined className="RightArrow" />
                <div>
                  {translate('ProductStep.goBackButton')}
                </div>
              </div>
            )}
            <div
              className={`Button Primary ${disableNextStep ? 'Disable' : ''}`}
              onClick={handleGoNextPage}
            >
              {translate('ProductStep.goNextButton')}
            </div>
          </div>
        </>
      )}
    </>
  );
}
