export const getClientOrders = (limit: number, offset: number) => `
  query getOrders(
    $where: ClientOrders_bool_exp!,
    $sorting: [ClientOrders_order_by!]
  ) {
    ClientOrders(limit: ${limit}, offset: ${offset}, where: $where, order_by: $sorting) {
      id
      code
      customerName
      customerEmail
      note
      status
      date
      shippingType
      shippingCost
      shippingDiscount
      globalDiscount
      platformCode
      invoiceSignedUrl
      customerPhone
      process
      cancelReason
      ClientId
      LocalId
      CommuneId
      PointOfSaleId
      OrderTypeId
      shippingAddress 
      OrderType {
        id
        name
      } 
      Local {
        id
        name
      }
      Orders {
        id
        status
        internalCode
        shippingType
        incidenceReason
        Shipments(order_by: { id: asc }) {
          id
          status
        }
        Local {
          id
          name
        }
        OrderDetails {
          id
          ProductId
          price
          amount
          discount
        }
      }
    }
    ClientOrders_aggregate(where: $where) {
      aggregate {
        total:count
      }
    }
  }
`;
