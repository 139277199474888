import {
  useEffect, useState, useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { GlobalContext } from '../../store';
import textInputToNumber from '../../helpers/textInputToNumber';
import Loading from '../../components/LoadingComponent';
import capitalizeFirstLetter from '../../helpers/capitalizeFirstLetter';
import { IClientOrder } from '../../interfaces/IOrder';
import LoadingPage from '../../components/LoadingPageComponent';
import { useTranslate } from '../../hooks';
import AlertDialog from '../../components/HomeComponents/AlertDialog';

export default function WithdrawalAll() {
  const { user, api } = useContext(GlobalContext);
  const translate = useTranslate('Page.WithdrawalAll');
  const navigate = useNavigate();
  const [withdrawalOrders, setWithdrawalOrders] = useState<IClientOrder[]>([]);
  const [countWithdrawals, setCountWithdrawals] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleOnClick(withdrawalOrder: IClientOrder) {
    navigate(`/home/withdrawals/${withdrawalOrder.id}`, {
      state: {
        withdrawalOrder,
      },
    });
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const posIntegration = await api.getClientPOSIntegration(user.connectableId, 'clients-platform');
      if (posIntegration) {
        const [orderTypeWithdrawal] = await api.getOrderTypes({ filter: { name: ['WO'] } });
        const filter = {
          ClientId: user.connectableId, PointOfSaleId: posIntegration.id, OrderTypeId: orderTypeWithdrawal.id,
        };
        const sort = [{ id: 'desc' }];
        const response = await api.getClientOrders({ filter, sort });
        setWithdrawalOrders(response.clientOrders);
        setCountWithdrawals(response.count);
        setLoading(false);
      } else {
        setLoading(false);
        setOpenAlertDialog(true);
        setModalAlertTitle(translate('modalErrorTitle'));
        setModalAlertText(`${translate('modalIntegrationErrorDescription')}`);
      }
    })();
  }, [user.connectableId]);

  if (loading) {
    return (
      <div className="PageContainer">
        <LoadingPage />
      </div>
    );
  }

  return (
    <div className="FormHistoryContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openAlertDialog}
        setOpen={setOpenAlertDialog}
      />
      <div className="FormHistoryTitle">
        {translate('title')}
      </div>
      <Paper sx={{ width: '90%' }}>
        <TableContainer sx={{ minHeight: 620, maxHeight: 620 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontFamily: 'Lato', fontSize: '16px' }}>
                  {translate('Columns.id')}
                </TableCell>
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  align="center"
                >
                  {translate('Columns.status')}
                </TableCell>
                <TableCell
                  sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                  align="center"
                >
                  {translate('Columns.date')}
                </TableCell>
              </TableRow>
            </TableHead>
            {withdrawalOrders.length === 0 ? (
              <TableBody>
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                    colSpan={4}
                  >
                    {translate('noWithdrawal')}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {loading ? (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell
                      sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                      colSpan={4}
                    >
                      <Loading />
                    </TableCell>
                  </TableRow>
                ) : (
                  withdrawalOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((clientOrder: IClientOrder) => (
                      <TableRow
                        sx={{ cursor: 'pointer' }}
                        hover
                        tabIndex={-1}
                      >
                        <TableCell
                          onClick={() => handleOnClick(clientOrder)}
                          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                        >
                          {textInputToNumber((clientOrder.id as number).toString(), true)}
                        </TableCell>
                        <TableCell
                          onClick={() => handleOnClick(clientOrder)}
                          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                          align="center"
                        >
                          {capitalizeFirstLetter(clientOrder.status)}
                        </TableCell>
                        <TableCell
                          onClick={() => handleOnClick(clientOrder)}
                          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
                          align="center"
                        >
                          {new Date(clientOrder.date || '').getUTCDate()}
                          /
                          {new Date(clientOrder.date || '').getUTCMonth() + 1}
                          /
                          {new Date(clientOrder.date || '').getUTCFullYear()}
                        </TableCell>
                      </TableRow>
                    )))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={countWithdrawals}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={translate('rowsPerPage')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
