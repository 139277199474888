import {
  useState, useEffect, useContext, SyntheticEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import UncheckedIcon from '@ant-design/icons/MinusSquareOutlined';
import { GlobalContext } from '../../store';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import NavigableTable from '../../NavigableTable/NavigableTable';
import { IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import LoadingPage from '../../components/LoadingPageComponent';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import IProduct from '../../interfaces/IProduct';
import ToolTip from '../../components/Tooltip';
import IGetTasksStatus from '../../interfaces/IGetTasksStatus';
import { useTranslate } from '../../hooks';
import uploadFileToS3 from '../../helpers/uploadFileToS3';

export default function EditDeliveryRates() {
  const {
    apiFunctions, state, user,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const translate = useTranslate('Page.EditProducts');

  let taskId: string | null = null;
  let retryTime = 20;
  let delayTime = 3000;
  const columns: IColumnData[] = [
    { title: translate('Columns.sku'), type: 'text', editable: false },
    { title: translate('Columns.name'), type: 'text', editable: true },
    { title: translate('Columns.barcode'), type: 'text', editable: true },
    {
      title: translate('Columns.isPack'),
      type: 'checkbox',
      editable: true,
      selectedAll: false,
      selectedAllIcon: <UncheckedIcon />,
    },
    {
      title: translate('Columns.isWeighted'),
      type: 'checkbox',
      editable: true,
      selectedAll: false,
      selectedAllIcon: <UncheckedIcon />,
    },
    { title: translate('Columns.familySku'), type: 'text', editable: true },
    { title: translate('Columns.price'), type: 'number', editable: true },
    { title: translate('Columns.safetyStock'), type: 'number', editable: true },
    {
      title: translate('Columns.ageVerification'),
      type: 'checkbox',
      editable: true,
      selectedAll: false,
      selectedAllIcon: <UncheckedIcon />,
    },
    { title: translate('Columns.expirationAlert'), type: 'number', editable: true },
    // agregar Size
  ];
  async function getTaskResponse() {
    if (retryTime > 0) {
      // The client's tasks are obtained from dynamoDb
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      // If the answer was failed, the item creation was wrong
      if (res?.status === 'FAILED') {
        const responseMessage = res.executions.message;
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        // If the response was successful, the item creation was well done
        setLoading(false);
        navigate('/home/allproducts', {
          state: {
            editSuccess: true,
          },
        });
      } else if (res?.status === 'ERROR') {
        // If the answer was error, it did not find the creation of items in the queue
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle4'));
        setModalAlertText(translate('Modal.errorText4'));
      } else {
        // This case is for when the status is PENDING, the timeout will re-execute the
        // function until one of the previous cases is triggered
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      setLoading(false);
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      if (res?.status === 'FAILED' || res?.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        navigate('/home/allproducts', {
          state: {
            editSuccess: true,
          },
        });
      } else {
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.noticeTitle'));
        setModalAlertText(translate('Modal.noticeText'));
      }
    }
  }
  // function to submit form
  async function handleSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setDisabledSubmitBtn(true);
    setLoading(true);
    const bodyData: IProduct[] = [];
    const itemsMissingInfo: string[] = [];
    dataToShow.forEach((item: IRowData) => {
      if (item.row[1].value === '') {
        itemsMissingInfo.push(item.row[0].value as string);
      }
      const itemsInfo = {
        id: Number(item.index),
        name: item.row[1].value as string,
        barcode: item.row[2].value as string,
        isPack: item.row[3].value === translate('yes'),
        isWeighted: item.row[4].value === translate('yes'),
        familySku: item.row[5].value as string,
        pvp: item.row[6].value === null ? 0 : Number(item.row[6].value),
        safetyStock: item.row[7].value === null ? 0 : Number(item.row[7].value),
        ageVerification: item.row[8].value === translate('yes'),
        expirationAlert: item.row[9].value === null ? 0 : Number(item.row[9].value),
        // agregar Size
      };
      bodyData.push(itemsInfo);
    });
    setLoading(false);
    // Check if all required info is given
    if (itemsMissingInfo.length > 0) {
      const missingData = itemsMissingInfo.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle1'));
      setModalAlertText(translate('Modal.errorText1', { message: missingData }));
    } else {
      // Upload data to s3
      const id = uuid();
      const fileToUpload = JSON.stringify(bodyData);
      const res = uploadFileToS3(
        fileToUpload,
        `massive/input/${id}`,
      );
      if (res.includes('Error')) {
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorUploadingCsvTitle'));
        setModalAlertText(translate('Modal.errorUploadingCsvText', { error: res }));
      } else {
        const body = {
          queue: 'models/products',
          event: 'update',
          clientId: user.connectableId,
          pathName: 'api/products',
          processedItemsIds: [],
          cursor: 0,
          lambdasAlreadyProcessed: 0,
          fileName: id,
        };
        try {
          taskId = await apiFunctions.postEnqueueTask(body);
          if (taskId === null) {
            setLoading(false);
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.errorTitle2'));
            setModalAlertText(translate('Modal.errorText2'));
          } else {
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.sentTitle'));
            setModalAlertText(translate('Modal.sentText'));
            getTaskResponse();
          }
        } catch (error) {
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorTitle2'));
          setModalAlertText(translate('Modal.errorText2'));
          setDisabledSubmitBtn(false);
        }
      }
    }
    setDisabledSubmitBtn(false);
  }

  useEffect(() => {
    if (dataToShow.length === 0) {
      setLoading(true);
      const list: IRowData[] = [];
      (state.filteredItemsToEdit as IProduct[]).forEach((product: IProduct) => {
        const cellData = [
          { index: 0, value: product.rawSku, isDisable: false },
          { index: 1, value: product.name, isDisable: false },
          { index: 2, value: product.barcode, isDisable: false },
          { index: 3, value: product.isPack ? translate('yes') : translate('no'), isDisable: false },
          { index: 4, value: product.isWeighted ? translate('yes') : translate('no'), isDisable: false },
          { index: 5, value: product.familySku, isDisable: false },
          { index: 6, value: product.pvp, isDisable: false },
          { index: 7, value: product.safetyStock, isDisable: false },
          { index: 8, value: product.ageVerification ? translate('yes') : translate('no'), isDisable: false },
          { index: 9, value: product.expirationAlert, isDisable: false },
          // agregar Size
        ];
        const rowData: IRowData = { index: product.id, row: cellData };
        list.push(rowData);
      });
      setDataToShow(list);
      setLoading(false);
    }
  }, [dataToShow]);

  return (
    <div className="PageContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      {loading ? (
        <LoadingPage />
      ) : (null)}
      <div className="FormMainContainer">
        <GoBackButton
          goBackPath="/home/allproducts"
        />
        <div className="FormHistoryTitleTooltipContainer">
          <div className="FormHistoryTitle">
            {translate('editProducts')}
          </div>
          <div className="FormHistoryTitleTooltip">
            <ToolTip
              color="dark"
              text={translate('tooltip')}
            />
          </div>
        </div>
        <form
          className="Form"
          onSubmit={handleSubmit}
        >
          <div className="FormContainerProducts">
            <NavigableTable
              dataToShow={dataToShow}
              setDataToShow={setDataToShow}
              columns={columns}
              loading={loading}
            />
          </div>
          <div className="FormFooter">
            <button
              className="GoBackButton"
              type="submit"
              disabled={disabledSubmitBtn}
            >
              {translate('editProducts')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
