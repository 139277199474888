import {
  useState, useEffect, useContext, SyntheticEvent,
} from 'react';
import { v4 as uuid } from 'uuid';
import { Divider } from '@mui/material';
import { GlobalContext } from '../../store';
import DropFile from '../../components/HomeComponents/DropFile';
import AlertDialog from '../../components/HomeComponents/AlertDialog';
import 'react-datepicker/dist/react-datepicker.css';
import NavigableTable from '../../NavigableTable/NavigableTable';
import LoadingPage from '../../components/LoadingPageComponent';
import { ICellData, IColumnData, IRowData } from '../../interfaces/INavigableTableObjects';
import IProductCreate from '../../interfaces/IProductCreate';
import IProduct from '../../interfaces/IProduct';
import downloadFileFromS3 from '../../helpers/downloadFileFromS3';
import GoBackButton from '../../components/HomeComponents/GoBackButton';
import uploadCsvData from '../../helpers/uploadCsvData';
import IGetTasksStatus from '../../interfaces/IGetTasksStatus';
import { useTranslate } from '../../hooks';
import uploadFileToS3 from '../../helpers/uploadFileToS3';

export default function ProductsCreate() {
  const {
    api, apiFunctions, user, client,
  } = useContext(GlobalContext);
  const [tableColumns, setTableColumns] = useState<string[]>([]);
  const [rowsValues, setRowsValues] = useState<unknown[]>([]);
  const [dataToShow, setDataToShow] = useState<IRowData[]>([]);
  const [columns, setColumns] = useState<IColumnData[]>([]);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [createProductsSubmited, setCreateProductsSubmited] = useState<boolean>(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const translate = useTranslate('Page.ProductsCreate');

  const columnsShouldHave = [
    translate('CSVColumns.sku'),
    translate('CSVColumns.name'),
    translate('CSVColumns.barcode'),
    translate('CSVColumns.isPack'),
    translate('CSVColumns.price'),
    translate('CSVColumns.storageType'),
    translate('CSVColumns.imageUrl'),
    translate('CSVColumns.ageVerification'),
    translate('CSVColumns.needsLotTracking'),
    // agregar Size
  ];
  let taskId: string | null = null;
  let retryTime = 20;
  let delayTime = 3000;
  const hasDuplicates = (arr: string[]) => arr.length !== new Set(arr).size;
  const findDuplicates = (arr: string[]) => {
    const sortedArr = arr.slice().sort();
    const results: string[] = [];
    for (let i = 0; i < sortedArr.length - 1; i += 1) {
      if (sortedArr[i + 1] === sortedArr[i]) {
        results.push(sortedArr[i]);
      }
    }
    return results;
  };

  function cleanUploadedDocument() {
    setDataToShow([]);
    setCreateProductsSubmited(true);
  }

  async function getTaskResponse() {
    if (retryTime > 0) {
      // The client's 'products' tasks are obtained from dynamoDb
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      // If the answer was failed, the product creation was wrong
      if (res.status === 'FAILED') {
        const responseMessage = res.executions.message;
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res.status === 'SUCCESS') {
        // If the response was successful, the product creation was well done
        cleanUploadedDocument();
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
      } else if (res.status === 'ERROR') {
        // If the answer was error, it did not find the creation of products in the queue
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorTitle6'));
        setModalAlertText(translate('Modal.errorText6'));
      } else {
        // This case is for when the status is PENDING, the timeout will re-execute the
        // function until one of the previous cases is triggered
        // After the first execution, the delayTime is changed to 15 sec to give a
        // a longer response time for product creation
        setTimeout(getTaskResponse, delayTime);
        delayTime = 15000;
      }
      retryTime -= 1;
    } else {
      setOpenModalAlert(true);
      const res: IGetTasksStatus = await apiFunctions.getTasksStatus((taskId as string));
      if (res?.status === 'FAILED' || res?.status === 'ERROR') {
        const responseMessage = res.executions?.message || 'Unexpected error';
        setModalAlertTitle(translate('Modal.failedTitle'));
        setModalAlertText(translate('Modal.failedText', { message: responseMessage }));
      } else if (res?.status === 'SUCCESS') {
        cleanUploadedDocument();
        setModalAlertTitle(translate('Modal.successTitle'));
        setModalAlertText(translate('Modal.successText'));
      } else {
        setModalAlertTitle(translate('Modal.noticeTitle'));
        setModalAlertText(translate('Modal.noticeText'));
      }
    }
  }

  async function submitProducts(e: SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    setDisabledSubmitBtn(true);
    const result = await api.getClientProducts({});
    const clientProducts = result.products;
    const createdProductsRawSku = clientProducts.map((product: IProduct) => product.rawSku);
    const products: IProductCreate[] = [];
    const productsAlreadyExists: string[] = [];
    const productsMissingInfo: string[] = [];
    const productsSku: string[] = [];
    dataToShow.forEach((product: IRowData) => {
      if (product.row[1].value === '' || product.row[2].value === ''
      || product.row[4].value === '' || product.row[5].value === '' || product.row[6].value === ''
      || product.row[7].value === '' || product.row[8].value === '' || product.row[9].value === '') {
        productsMissingInfo.push(product.row[0].value as string);
      }
      const isPack = (product.row[4].value as string).toLowerCase() === translate('yes');
      const ageVerification = (product.row[8].value as string).toLowerCase() === translate('yes');
      const needsLotTracking = (product.row[9].value as string).toLowerCase() === translate('yes');
      const productInfo = {
        sku: `${client.prefix}-${product.row[1].value as string}`,
        rawSku: product.row[1].value as string,
        name: product.row[2].value as string,
        barcode: product.row[3].value as string,
        isPack,
        pvp: Number(product.row[5].value),
        storageType: (product.row[6].value as string).toLowerCase(),
        imageUrl: product.row[7].value as string,
        ageVerification,
        needsLotTracking,
        // agregar Size
      };
      products.push(productInfo);
      productsSku.push(product.row[1].value as string);
      if (createdProductsRawSku.includes(product.row[1].value as string)) {
        productsAlreadyExists.push(product.row[0].value as string);
      }
    });
    setLoading(false);
    // Check if products doesn't repeat
    if (hasDuplicates(productsSku)) {
      const duplicateElements = findDuplicates(productsSku);
      const message = duplicateElements.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle1'));
      setModalAlertText(translate('Modal.errorText1', { message }));
    } else if (productsAlreadyExists.length > 0) {
      // Check if products doesn't exists
      const productsThatExists = productsAlreadyExists.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle3'));
      setModalAlertText(translate('Modal.errorText3', { message: productsThatExists }));
    } else if (productsMissingInfo.length > 0) {
      // Check if all required info is given
      const missingData = productsMissingInfo.join(', ');
      setOpenModalAlert(true);
      setModalAlertTitle(translate('Modal.errorTitle2'));
      setModalAlertText(translate('Modal.errorText2', { message: missingData }));
    } else {
      // Upload data to s3
      const id = uuid();
      const fileToUpload = JSON.stringify(products);
      const response = uploadFileToS3(
        fileToUpload,
        `massive/input/${id}`,
      );
      if (response.includes('Error')) {
        setLoading(false);
        setOpenModalAlert(true);
        setModalAlertTitle(translate('Modal.errorUploadingCsvTitle'));
        setModalAlertText(translate('Modal.errorUploadingCsvText', { error: response }));
      } else {
        const body = {
          queue: 'models/products',
          event: 'create',
          clientId: user.connectableId,
          pathName: 'api/products',
          processedItemsIds: [],
          cursor: 0,
          lambdasAlreadyProcessed: 0,
          fileName: id,
        };
        try {
          taskId = await apiFunctions.postEnqueueTask(body);
          if (taskId === null) {
            setLoading(false);
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.errorTitle4'));
            setModalAlertText(translate('Modal.errorText4'));
          } else {
            getTaskResponse();
            setOpenModalAlert(true);
            setModalAlertTitle(translate('Modal.sentTitle'));
            setModalAlertText(translate('Modal.sentText'));
          }
        } catch (error) {
          setOpenModalAlert(true);
          setModalAlertTitle(translate('Modal.errorTitle4'));
          setModalAlertText(translate('Modal.errorText4'));
          setDisabledSubmitBtn(false);
        }
      }
    }
    setDisabledSubmitBtn(false);
  }
  useEffect(() => {
    const defineColumns: IColumnData[] = [];
    defineColumns.push({ title: translate('Columns.number'), type: 'number', editable: false });
    tableColumns.forEach((columnName: string) => {
      if (columnName === translate('Columns.price') || columnName === translate('Columns.safetyStock')
      || columnName === translate('Columns.expirationAlert')) {
        defineColumns.push({ title: columnName, type: 'number', editable: true });
      } else if (columnName === translate('Columns.isPack') || columnName === translate('Columns.isWeighted')
      || columnName === translate('Columns.ageVerification') || columnName === translate('Columns.lotTracking')) {
        defineColumns.push({ title: columnName, type: 'checkbox', editable: true });
      } else {
        defineColumns.push({ title: columnName, type: 'text', editable: true });
      }
    });
    setColumns(defineColumns);
    const list: IRowData[] = [];
    rowsValues.forEach((data: unknown, idx: number) => {
      const cellData: ICellData[] = [];
      cellData.push({ index: 0, value: idx + 1, isDisable: false });
      (data as string[]).forEach((value: string, index: number) => {
        if (index + 1 === 4 || index + 1 === 8 || index + 1 === 9) {
          if ((value).toLowerCase().includes(translate('yes')) || (value).toLowerCase().includes(translate('yesAlt'))) {
            cellData.push({ index: index + 1, value: translate('yes'), isDisable: false });
          } else {
            cellData.push({ index: index + 1, value: translate('no'), isDisable: false });
          }
        } else {
          cellData.push({ index: index + 1, value, isDisable: false });
        }
      });
      const rowData: IRowData = { index: idx, row: cellData };
      list.push(rowData);
    });
    setDataToShow(list);
  }, [tableColumns, rowsValues]);

  return (
    <div className="PageContainer">
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      {loading ? (
        <LoadingPage />
      ) : (
        <div className="FormMainContainer">
          <GoBackButton
            goBackPath="/home/allproducts"
          />
          <form
            className="Form"
            onSubmit={submitProducts}
          >
            <div className="FormTitle">
              {translate('title')}
            </div>
            <Divider />
            <div className="FormContainer">
              <div className="FormLabel">
                {translate('loadCSV')}
                <div
                  className="UploadTutorialText"
                  onClick={
                    () => window.open(
                      'https://get-nomad.notion.site/Creaci-n-de-productos-en-Nomad-Masivamente'
                      + '-56a9681bdc16489098d38e509ef1b5c0?pvs=4',
                      '_blank',
                    )
                  }
                >
                  {translate('bulkUploadTutorial')}
                </div>
              </div>
              <div className="FileUploadItemsContainer">
                {/* File Uploader */}
                <DropFile
                  changeHandler={
                    (file: File) => uploadCsvData(
                      file,
                      columnsShouldHave,
                      setOpenModalAlert,
                      setModalAlertTitle,
                      setModalAlertText,
                      setTableColumns,
                      setRowsValues,
                      translate,
                    )
                  }
                  createItemsSubmitted={createProductsSubmited}
                  setCreateItemsSubmitted={setCreateProductsSubmited}
                />
                <div className="UploadItemsText">
                  {translate('download1')}
                  <div
                    onClick={() => downloadFileFromS3(
                      'Planillas/Ejemplo planilla creación productos.csv',
                      'text/csv;charset=utf-8',
                    )}
                    className="UploadItemsTemplate"
                  >
                    {translate('download2')}
                  </div>
                  {translate('download3')}
                </div>
              </div>
            </div>
            <Divider />
            {dataToShow.length > 0 ? (
              <>
                <div className="FormContainerItems">
                  <NavigableTable
                    dataToShow={dataToShow}
                    setDataToShow={setDataToShow}
                    columns={columns}
                    loading={false}
                  />
                </div>
                <div className="FormFooter">
                  <button
                    className="GoBackButton"
                    type="submit"
                    disabled={disabledSubmitBtn}
                  >
                    {translate('createProducts')}
                  </button>
                </div>
              </>
            ) : (null)}
          </form>
        </div>
      )}
    </div>
  );
}
