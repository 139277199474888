import {
  useEffect, useState, useContext,
} from 'react';
import { useTranslate } from '../../hooks';
import { SelectInput } from '../../components/HomeComponents/SelectInput';
import { IClientOrderData } from '../../interfaces/IOrder';
import { GlobalContext } from '../../store';
import Loading from '../../components/LoadingComponent';
import { IChargeRate } from '../../interfaces/IChargeRate';
import { IOrderType } from '../../interfaces';

interface Props {
  goNextPage: () => void;
  clientOrderData: IClientOrderData;
  setClientOrderData: React.Dispatch<React.SetStateAction<IClientOrderData>>;
  orderTypes: IOrderType[];
}

export default function Type(props: Props) {
  const {
    clientOrderData, setClientOrderData, goNextPage, orderTypes,
  } = props;
  const translate = useTranslate('Page.CreateOrder');
  const { api } = useContext(GlobalContext);
  const [disable, setDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOrderType, setSelectedOrderType] = useState<IOrderType | null>(null);
  const [chargeRates, setChargeRates] = useState<IChargeRate[]>([]);
  const [filteredChargeRates, setFilteredChargeRates] = useState<IChargeRate[]>([]);
  const [selectedChargeRate, setSelectedChargeRate] = useState<IChargeRate | null>(null);

  function saveData() {
    if (selectedOrderType && selectedChargeRate) {
      setClientOrderData({
        ...clientOrderData,
        OrderTypeId: selectedOrderType.id,
        OrderType: selectedOrderType,
        ChargeRateId: selectedChargeRate.id,
        ChargeRate: selectedChargeRate,
      });
      goNextPage();
    }
  }

  // Set types when selected OrderType
  useEffect(() => {
    if (chargeRates.length > 0 && selectedOrderType) {
      const usableData = chargeRates
        .filter((chargeRate) => chargeRate.OrderTypeId === selectedOrderType.id);
      setFilteredChargeRates(usableData);
      setSelectedChargeRate(usableData[0]);
    }
  }, [chargeRates, selectedOrderType]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      // set chargeRates
      const data = await api.getClientChargeRates(clientOrderData.ClientId);
      setChargeRates(data);
      if (clientOrderData.OrderTypeId) {
        const orderType = orderTypes.find((ot) => ot.id === clientOrderData.OrderTypeId);
        if (orderType) {
          setSelectedOrderType(orderType);
        }
      }
      setLoading(false);
    })();
  }, [clientOrderData.OrderTypeId]);

  // Verification required inputs
  useEffect(() => {
    if (!selectedOrderType || !selectedChargeRate) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedOrderType, selectedChargeRate]);

  if (loading) {
    return (
      <>
        <div className="PageSubtitleContainer">
          {translate('TypeStep.subtitle')}
        </div>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="PageSubtitleContainer">
        {translate('TypeStep.subtitle')}
      </div>
      <div className="DirectionStepContainer">
        <div className="DirectionLabel">{translate('TypeStep.businessModel')}</div>
        <div className="SelectWrapperLargeWidth">
          <SelectInput<IOrderType>
            data={orderTypes}
            keyValue="id"
            selectValue="id"
            displayValue="name"
            selected={selectedOrderType}
            setSelected={setSelectedOrderType}
          />
        </div>
        <div className="DirectionLabel">{translate('TypeStep.commission')}</div>
        <div className="SelectWrapperLargeWidth">
          <SelectInput<IChargeRate>
            data={filteredChargeRates}
            keyValue="id"
            selectValue="id"
            displayValue="type"
            selected={selectedChargeRate}
            setSelected={setSelectedChargeRate}
          />
        </div>
      </div>
      <div className="BottomRow">
        <div
          className={`Button Primary ${disable ? 'Disable' : ''}`}
          onClick={saveData}
        >
          {translate('TypeStep.goNextButton')}
        </div>
      </div>
    </>
  );
}
