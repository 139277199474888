import { useEffect, useState, useContext } from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { IClientOrderData } from '../../interfaces/IOrder';
import { GlobalContext } from '../../store';
import { useTranslate } from '../../hooks';
import IAvailableRatesRequestBody from '../../interfaces/IAvailableRatesRequestBody';
import IAvailableRates from '../../interfaces/IAvailableRates';
import { IRowData } from '../../interfaces/INavigableTableObjects';
import ToolTip from '../../components/Tooltip';
import Loading from '../../components/LoadingComponent';
import textInputToNumber from '../../helpers/textInputToNumber';

interface Props {
  goNextPage: () => void;
  goPreviousPage: () => void;
  clientOrderData: IClientOrderData;
  orderDetails: IRowData[];
  setClientOrderData: React.Dispatch<React.SetStateAction<IClientOrderData>>;
  setRateId: React.Dispatch<React.SetStateAction<number>>;
  rateId: number;
}

interface ProgrammedTimeItem {
  value: string;
  description: string;
}

interface RatesNewData {
  shippingType: string;
  shippingCost: number;
}

export default function Rates(props: Props) {
  const {
    clientOrderData, setClientOrderData, goNextPage, goPreviousPage, orderDetails, setRateId, rateId,
  } = props;
  const translate = useTranslate('Page.CreateOrder');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProgrammedTime, setSelectedProgrammedTime] = useState<string | null>(clientOrderData.pat);
  const [availableRates, setAvailableRates] = useState<IAvailableRates[]>([]);
  const [availableProgrammedTime, setAvailableProgrammedTime] = useState<ProgrammedTimeItem[]>([]);
  const [showScheduled, setShowScheduled] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [rateNewData, setRateNewData] = useState<RatesNewData>({
    shippingType: clientOrderData.shippingType,
    shippingCost: clientOrderData.shippingCost,
  });
  const { api } = useContext(GlobalContext);

  function saveData() {
    setClientOrderData({ ...clientOrderData, ...rateNewData, pat: selectedProgrammedTime });
    goNextPage();
  }

  function getWeekdayString(date: Date) {
    return date.toLocaleDateString(translate('RateStep.language'), { weekday: 'long' });
  }

  function getMonthString(date: Date) {
    return date.toLocaleDateString(translate('RateStep.language'), { month: 'long' });
  }

  function getUTCDate(date: Date, time: string) {
    if (time === 'am') {
      return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 10);
    }
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 17);
  }

  function setRateData(rate: IAvailableRates) {
    setRateNewData({ ...rateNewData, shippingType: rate.shippingType, shippingCost: rate.price });
    setRateId(rate.id);
  }

  function setProgrammedExpressTime(today: Date) {
    const curHr = today.getHours() + 3; // Time of recieving order
    const now = new Date();
    const tomorrow = new Date(today.setDate(today.getDate() + 1));
    const afterTomorrow = new Date(today.setDate(today.getDate() + 1));
    const list = [
      {
        value: (new Date(getUTCDate(now, 'pm'))).toISOString(),
        description: `${translate('RateStep.today')} ${getWeekdayString(now)} ${now.getDate()}
        ${translate('RateStep.of')} ${getMonthString(now)} ${translate('RateStep.pmTime')}`,
      },
      {
        value: (new Date(getUTCDate(tomorrow, 'am'))).toISOString(),
        description: `${translate('RateStep.recieve')} ${getWeekdayString(tomorrow)} ${tomorrow.getDate()}
        ${translate('RateStep.of')} ${getMonthString(tomorrow)} ${translate('RateStep.amTime')}`,
      },
      {
        value: (new Date(getUTCDate(tomorrow, 'pm'))).toISOString(),
        description: `${translate('RateStep.recieve')} ${getWeekdayString(tomorrow)} ${tomorrow.getDate()}
        ${translate('RateStep.of')} ${getMonthString(tomorrow)} ${translate('RateStep.pmTime')}`,
      },
      {
        value: (new Date(getUTCDate(afterTomorrow, 'am'))).toISOString(),
        description: `${translate('RateStep.recieve')} ${getWeekdayString(afterTomorrow)} ${afterTomorrow.getDate()}
        ${translate('RateStep.of')} ${getMonthString(afterTomorrow)} ${translate('RateStep.amTime')}`,
      },
    ];
    if (curHr < 12) {
      list.unshift(
        {
          value: (new Date(getUTCDate(now, 'am'))).toISOString(),
          description: `${translate('RateStep.today')} ${getWeekdayString(now)} ${now.getDate()}
          ${translate('RateStep.of')} ${getMonthString(now)} ${translate('RateStep.amTime')}`,
        },
      );
    } else {
      list.push(
        {
          value: (new Date(getUTCDate(afterTomorrow, 'pm'))).toISOString(),
          description: `${translate('RateStep.recieve')} ${getWeekdayString(afterTomorrow)} ${afterTomorrow.getDate()}
          ${translate('RateStep.of')} ${getMonthString(afterTomorrow)} ${translate('RateStep.pmTime')}`,
        },
      );
    }
    if (now.getHours() > 18) {
      // remove today pm time and add aditional time
      list.shift();
      const overmorrow = new Date(today.setDate(today.getDate() + 1));
      list.push(
        {
          value: (new Date(getUTCDate(overmorrow, 'am'))).toISOString(),
          description: `${translate('RateStep.recieve')} ${getWeekdayString(overmorrow)} ${overmorrow.getDate()}
          ${translate('RateStep.of')} ${getMonthString(overmorrow)} ${translate('RateStep.amTime')}`,
        },
      );
    }
    // default time to empty to be assign in backend
    list[0].value = '';
    // set default pat
    if (clientOrderData.pat === '') {
      setSelectedProgrammedTime(list[0].value);
    }
    setAvailableProgrammedTime(list);
  }

  function setProgrammedSameDayTime(today: Date) {
    const curHr = today.getHours();
    const dayOne = new Date();
    const dayTwo = new Date(today.setDate(today.getDate() + 1));
    const dayThree = new Date(today.setDate(today.getDate() + 1));
    const dayFour = new Date(today.setDate(today.getDate() + 1));
    const dayFive = new Date(today.setDate(today.getDate() + 1));
    const list = [
      {
        value: (new Date(getUTCDate(dayOne, 'am'))).toISOString(),
        description: `${translate('RateStep.today')} ${getWeekdayString(dayOne)} ${dayOne.getDate()}
        ${translate('RateStep.of')} ${getMonthString(dayOne)}`,
      },
      {
        value: (new Date(getUTCDate(dayTwo, 'am'))).toISOString(),
        description: `${translate('RateStep.tomorrow')} ${getWeekdayString(dayTwo)} ${dayTwo.getDate()}
        ${translate('RateStep.of')} ${getMonthString(dayTwo)}`,
      },
      {
        value: (new Date(getUTCDate(dayThree, 'am'))).toISOString(),
        description: `${translate('RateStep.recieve')} ${getWeekdayString(dayThree)} ${dayThree.getDate()}
        ${translate('RateStep.of')} ${getMonthString(dayThree)}`,
      },
      {
        value: (new Date(getUTCDate(dayFour, 'am'))).toISOString(),
        description: `${translate('RateStep.recieve')} ${getWeekdayString(dayFour)} ${dayFour.getDate()}
        ${translate('RateStep.of')} ${getMonthString(dayFour)}`,
      },
      {
        value: (new Date(getUTCDate(dayFive, 'am'))).toISOString(),
        description: `${translate('RateStep.recieve')} ${getWeekdayString(dayFive)} ${dayFive.getDate()}
        ${translate('RateStep.of')} ${getMonthString(dayFive)}`,
      },
    ];
    if (curHr > 12) {
      list.shift();
      const daySix = new Date(today.setDate(today.getDate() + 1));
      list.push(
        {
          value: (new Date(getUTCDate(daySix, 'am'))).toISOString(),
          description: `${translate('RateStep.recieve')} ${getWeekdayString(daySix)} ${daySix.getDate()}
          ${translate('RateStep.of')} ${getMonthString(daySix)}`,
        },
      );
    }
    list[0].value = '';
    // set default pat
    if (clientOrderData.pat === '') {
      setSelectedProgrammedTime(list[0].value);
    }
    setAvailableProgrammedTime(list);
  }

  useEffect(() => {
    if (rateNewData.shippingType === 'express') {
      const today = new Date();
      setProgrammedExpressTime(today);
    } if (rateNewData.shippingType === 'same-day') {
      const today = new Date();
      setProgrammedSameDayTime(today);
    } else {
      setShowScheduled(false);
    }
  }, [rateNewData]);

  useEffect(() => {
    if (availableProgrammedTime.length > 0 && availableRates.length > 0) {
      setShowScheduled(true);
    }
  }, [availableProgrammedTime, availableRates]);

  useEffect(() => {
    (async () => {
      if (availableRates.length === 0) {
        setLoading(true);
        const addedProducts = orderDetails
          ?.filter((product) => (product.row[1].value as number) > 0);
        const items = addedProducts.map((detail: IRowData) => {
          const str = detail.row[0].value as string;
          const lastIndex = str.lastIndexOf('||');
          const rawSku = str.slice(0, lastIndex);
          return {
            sku: rawSku,
            quantity: detail.row[1].value as number,
            price: detail.row[2].value as number,
          };
        });
        const request: IAvailableRatesRequestBody = {
          communeId: null,
          shippingAddress: clientOrderData.shippingAddress,
          pointOfSaleId: clientOrderData.PointOfSaleId as number,
          items,
        };
        const [standardRates, nomadFleetRates] = await Promise.all([
          api.getAvailableDeliveryRates(request),
          api.getAvailableDeliveryRates({
            ...request,
            options: { courierId: 7, shippingTypes: ['same-day'] },
          }),
        ]);
        let filteredRates = standardRates;
        if (clientOrderData.OrderType?.name === 'B2B') {
          filteredRates = standardRates.filter(
            (rate) => !['same-day', 'express'].includes(rate.shippingType),
          );
          filteredRates = [...filteredRates, ...nomadFleetRates];
        }
        setAvailableRates(filteredRates);
        setLoading(false);
      }
    })();
  }, []);

  // Set initial data if there is
  useEffect(() => {
    if (rateNewData.shippingType) {
      setDisable(false);
    }
  }, [rateNewData]);

  return (
    <>
      <div className="InfoTableContainer">
        <div className="Column RateLeft">
          <div className="ColumnTitle">
            {translate('RateStep.subtitle')}
          </div>
          {loading && (
            <Loading />
          )}
          {availableRates.length === 0 && !loading && (
          <div>
            {translate('RateStep.noRates')}
          </div>
          )}
          {availableRates.length > 0 && !loading && (
            <div className="ItemsContainer">
              {availableRates.map((rate) => (
                <div
                  className="ItemWithPrice"
                  key={rate.id}
                >
                  <div
                    className="FlexRow"
                    style={{ width: '80%' }}
                  >
                    <input
                      type="radio"
                      value={rate.title}
                      checked={rateId === rate.id}
                      onChange={() => setRateData(rate)}
                    />
                    <div className="RateDescriptionContainer">
                      <div className="Title">
                        {rate.title}
                      </div>
                      <div className="Label">
                        {rate.description}
                      </div>
                    </div>
                  </div>
                  <div className="RatePrice">
                    {rate.price === 0 ? translate('RateStep.free')
                      : `$ ${textInputToNumber(rate.price.toString(), true)}`}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {showScheduled && !loading && (
        <div className="Column RateRight">
          <div className="ColumnTitle FlexRow">
            {translate('RateStep.programmedRate')}
            <ToolTip
              color="dark"
              text={translate('RateStep.toolTipText')}
            />
          </div>
          <div className="ItemsContainer">
            {availableProgrammedTime.map((time) => (
              <div className="Item">
                <input
                  type="radio"
                  value={time.value}
                  checked={selectedProgrammedTime === time.value}
                  onChange={(e) => {
                    setSelectedProgrammedTime(e.target.value);
                  }}
                />
                <div className="RateDescriptionContainer">
                  <div className="Title">
                    {time.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
      </div>
      <div className="BottomRow">
        <div
          className="GoBackOrderPage"
          onClick={goPreviousPage}
        >
          <LeftOutlined className="RightArrow" />
          <div>
            {translate('RateStep.goBackButton')}
          </div>
        </div>
        <div
          className={`Button Primary ${disable ? 'Disable' : ''}`}
          onClick={saveData}
        >
          {translate('RateStep.goNextButton')}
        </div>
      </div>
    </>
  );
}
