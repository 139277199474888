import { FormEvent, useEffect, useState } from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import LoadFileIcon from '@ant-design/icons/UploadOutlined';
import { IClientOrderData } from '../../interfaces/IOrder';
import { useTranslate } from '../../hooks';

interface Props {
  goNextPage: () => void;
  goPreviousPage: () => void;
  clientOrderData: IClientOrderData;
  setClientOrderData: React.Dispatch<React.SetStateAction<IClientOrderData>>;
  setUploadedInvoice: React.Dispatch<React.SetStateAction<HTMLInputElement | null>>;
  setOpenAlertDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAlertTitle: React.Dispatch<React.SetStateAction<string>>;
  setModalAlertText: React.Dispatch<React.SetStateAction<string>>;
}

export default function Data(props: Props) {
  const {
    clientOrderData, setClientOrderData, goNextPage, goPreviousPage, setUploadedInvoice, setOpenAlertDialog,
    setModalAlertTitle, setModalAlertText,
  } = props;
  const translate = useTranslate('Page.CreateOrder');
  const [name, setName] = useState<string>(clientOrderData.customerName);
  const [phone, setPhone] = useState<string>(clientOrderData.customerPhone);
  const [email, setEmail] = useState<string>(clientOrderData.customerEmail);
  const [notes, setNotes] = useState<string>(clientOrderData.note);
  const [disable, setDisable] = useState<boolean>(true);
  const [documentName, setDocumentName] = useState<string>('');

  function onChangeDocument(e: FormEvent<HTMLLabelElement>) {
    setUploadedInvoice(e.target as HTMLInputElement);
    let fileName = (e.target as HTMLInputElement).value;
    const { files } = e.target as HTMLInputElement;
    if (files && files?.length > 1) {
      setDocumentName(`${files?.length} ${translate('DataStep.uploadFiles')}`);
    } else {
      const i = fileName.lastIndexOf('\\');
      fileName = fileName.substring(i + 1);
      setDocumentName(fileName);
    }
  }

  function validateEmail(inputValue: string) {
    const valid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (inputValue.match(valid)) {
      return true;
    }
    return false;
  }

  function saveData() {
    if (validateEmail(email)) {
      setClientOrderData({
        ...clientOrderData, customerEmail: email, customerName: name, customerPhone: phone, note: notes,
      });
      goNextPage();
    } else {
      setOpenAlertDialog(true);
      setModalAlertTitle(translate('DataStep.modalTitle'));
      setModalAlertText(translate('DataStep.modalDescription'));
    }
  }

  // Verification required inputs
  useEffect(() => {
    if (name.length > 0 && email.length > 0 && (clientOrderData.OrderType?.name !== 'B2B' || documentName.length > 0)) {
      setDisable(false);
    }
  }, [name, email, documentName]);

  return (
    <>
      <div className="PageSubtitleContainer">
        {translate('DataStep.subtitle')}
      </div>
      <div className="DirectionStepContainer">
        <div className="DirectionLabel">{translate('DataStep.name')}</div>
        <input
          className="TextInput InputStyle"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="DirectionLabel">{translate('DataStep.email')}</div>
        <input
          className="TextInput InputStyle"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="DirectionLabel">{translate('DataStep.phone')}</div>
        <input
          className="TextInput InputStyle"
          type="tel"
          placeholder="988043324"
          maxLength={8}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        {clientOrderData.OrderType?.name === 'B2B' && (
          <>
            <div className="DirectionLabel">{translate('DataStep.invoiceDoc')}</div>
            <label
              className="InputFormDocumentUpload InputStyle"
              onChange={onChangeDocument}
              htmlFor="DocumentToUpload"
            >
              <input
                type="file"
                id="DocumentToUpload"
                accept="application/pdf"
                hidden
              />
              <LoadFileIcon className="InputFormIcon" />
              <div>
                {translate('DataStep.uploadInvoiceDoc')}
              </div>
            </label>
          </>
        )}
        <div
          className="InputFormDocumentUploadText InputStyle"
        >
          {documentName}
        </div>
        <div className="DirectionLabel">{translate('DataStep.notes')}</div>
        <textarea
          className="TextInput InputStyle InputHeight"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <div className="BottomRow">
        <div
          className="GoBackOrderPage"
          onClick={goPreviousPage}
        >
          <LeftOutlined className="RightArrow" />
          <div>
            {translate('DataStep.goNextButton')}
          </div>
        </div>
        <div
          className={`Button Primary ${disable ? 'Disable' : ''}`}
          onClick={saveData}
        >
          {translate('DataStep.goNextButton')}
        </div>
      </div>
    </>
  );
}
